
import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/Seo'

import {
  Container,
  Flex,
  Box,
  Heading,
  Text
} from '@chakra-ui/react'

import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import Gallery from '@browniebroke/gatsby-image-gallery'
import SliceZone from '../components/SliceZone/SliceZone'

const PortfolioTemplate = ({ data }) => {
  
  if (!data) return null
  
  const document = data.prismicPortfolioGallery.data

  //console.log(document.image[0].image1)

  const CustomWrapper = ({ children, onClick }) => (
    <Flex w="100%" flexWrap="wrap"className="custom-wrapper" onClick={onClick} role="button" width={{base: '100%', md: '33%', lg: '25%'}} p="2">
      { children }
    </Flex>
  )
  
  const lightboxOptions = {
    enableZoom: false,
  }

  const images = document.image.map(({ image1 }) => ({
    ...image1.localFile.childImageSharp
  })
  )

  console.log(images)

  return (
    <Layout>
      <Seo title={document.portfolio_title.text}  />
      <Container maxW="container.xl">
         <Box my="12">
            <Heading as="h1" fontSize="2.25rem" textTransform="uppercase" fontWeight="100" textAlign="center" mb={4}>
                {document.portfolio_title.text}
            </Heading>
            <Text>
                {document.portfolio_description.text}
            </Text>
        </Box>
        <SliceZone sliceZone={document.body} />
        <Flex className="gallery-container" mb="12" justifyContent="center">
          <Gallery images={images}
            customWrapper={CustomWrapper}
            lightboxOptions={lightboxOptions}
            gutter="0"
            imageCaptions={true}
          />
        </Flex>
      </Container>
    </Layout>
  )
}

export const query = graphql`
query PortfolioQuery($uid: String!) {
          prismicPortfolioGallery(uid: {eq: $uid}) {
            uid
            data {
              portfolio_title {
                text
              }
              portfolio_description {
                text
              }
              image {
                image1 {
                  alt
                  localFile {
                    childImageSharp {
                      full: gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                      thumb: gatsbyImageData(placeholder: BLURRED, aspectRatio: 1
                        transformOptions: {cropFocus: CENTER})
                    }
                  }
                }
                image_caption {
                  text
                }
              }
              featured_image {
                alt
              }
              body {
                ... on PrismicPortfolioGalleryDataBodyBeforeAfterCarousel {
                  id
                  slice_type
                  items {
                    image {
                      alt
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            aspectRatio: 1.5
                            placeholder: BLURRED
                            transformOptions: {cropFocus: CENTER}
                          )
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
    `

export default withPrismicPreview(PortfolioTemplate)
